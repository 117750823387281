export default {
  useEmailToReset: "Use Email Reset",
  emailAddress: "Email address",
  enterCaptcha: "Please enter the verification code",
  sendResetMail: "Send reset mail",
  captchaDoesNotMatch: "CaptchaDoesNotMatch",
  mailHasBeenSent: "Password change email has been sent",
  errorOccursWhenSendingMail:
    "An error occurred, please contact customer service",
};
