<template>
  <div class="footer_area">
    <!-- <div class="collapse-box">
      <el-collapse v-model="activeNames">
        <el-collapse-item :title="$t('footer.about')" name="1">
          <div>
            与现实生活一致：与现实生活的流程、逻辑保持一致，遵循用户习惯的语言和概念；
          </div>
        </el-collapse-item>
        <el-collapse-item :title="$t('footer.info')" name="2">
          <div>
            与现实生活一致：与现实生活的流程、逻辑保持一致，遵循用户习惯的语言和概念；
          </div>
        </el-collapse-item>
        <el-collapse-item :title="$t('footer.service')" name="3">
          <div>
            与现实生活一致：与现实生活的流程、逻辑保持一致，遵循用户习惯的语言和概念；
          </div>
        </el-collapse-item>
      </el-collapse>
    </div> -->
    <div class="contact_box">
      <div class="title">
        {{ $t("footer.contact") }}: <span class="gray">mtftoken@gmail.com</span>
      </div>
    </div>
    <div class="footer_text">2022 MTF Token</div>
  </div>
</template>

<script>
export default {
  name: "FooterComponent",
  data() {
    return {
      activeNames: 1,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/globalstyle.scss";

.footer_area {
  width: 100%;
  background-image: url("~@/assets/images/Rectangle.png");
  background-size: 1920px 100%;
  background-repeat: no-repeat;
  background-position: center;
  height: 150px;

  @include flex-set();
  .collapse-box {
    width: 95%;
  }
  .contact_box {
    width: 95%;
    @include flex-set();

    .title {
      width: 100%;
      @include font-set($white, 15px);
      margin: 20px 0 10px;
      display: flex;
      flex-direction: column;

      span {
        margin-top: 8px;
      }
    }

    .contactinfo {
      @include font-set(hsla(0, 0%, 100%, 0.6), 14px);
      line-height: 25px;
      width: 100%;
      text-align: left;
    }
    
    .gray {
      @include font-set(#697087, 14px);
    }
  }
  .footer_text {
    @include font-set($white, 12px);
    margin: 10px 0 20px;
  }
}
</style>

<style lang="scss">
@import "@/assets/style/globalstyle.scss";

.el-collapse-item__header {
  background: unset;
  background-color: unset !important;
  border-bottom: unset !important;
  @include font-set($white !important, 15px !important);
}
.el-collapse-item__wrap {
  background-color: unset !important;
  border-bottom: unset !important;
  margin-left: 15px;
  .el-collapse-item__content {
    color: $white !important;
    text-align: left;
  }
}
</style>
