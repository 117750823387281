<template>
  <div class="content">
    <form class="from" @submit="(e) => e.preventDefault()">
      <button id="useEmail" class="title" data-type="email">
        使用郵箱註冊
      </button>

      <input
        class="input"
        type="email"
        placeholder="邮箱地址"
        name="email"
        v-model="form.email"
        required
      />
      <!-- <div class="verification">
        <input class="input" type="text" placeholder="驗證碼" />
        <span>{{ "發送驗證碼" }}</span>
      </div> -->

      <input
        class="input"
        type="password"
        placeholder="登入密碼"
        v-model="form.password"
        autocomplete="off"
        required
      />

      <!-- <input
        class="input"
        type="password"
        placeholder="再次輸入登入密碼"
        autocomplete="off"
        required
      /> -->

      <label for="checkbox">
        <input type="checkbox" v-model="checked" />
        <span>我同意用戶協議和隱私政策。我也不是美國公民或者美國納稅人。</span>
      </label>

      <button class="btn" @click="register">註冊</button>

      <div class="footer">
        <router-link to="/login" class="link">登入</router-link>
      </div>
    </form>
  </div>
</template>

<script>
import { postMembers } from "@/services/Api.js";

export default {
  inject: ["reload"],
  name: "RegisterView",
  data() {
    return {
      checked: false,
      form: {
        email: "",
        password: "",
      },
    };
  },
  methods: {
    register() {
      console.log("test");
      if (this.checked) {
        if (this.form.email !== "" && this.form.password !== "") {
          postMembers(this.form)
            .then((res) => {
              console.log(res);
              if (res.data.success) {
                this.$message({
                  message: "註冊成功",
                  type: "success",
                  offset: 70,
                });
                this.$router.push("/").catch(() => {});
                this.reload();
              } else {
                this.$message({
                  message: res.data.errors[0].message,
                  type: "warning",
                  offset: 70,
                });
              }
            })
            .catch((err) => console.log(err));
        }
      } else {
        alert("請同意服務協議和隱私政策");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  min-height: 100vh;
  background-color: #292d39;
  padding-bottom: 2rem;

  .from {
    background-color: #fff;
    padding: 44px 16px;
    border-radius: 3px;
    margin-top: 2rem;
    width: calc(100% - 2rem);
    margin: 0 auto;
    margin-top: 2rem;

    .verification {
      position: relative;
      display: flex;
      align-items: center;
      margin-bottom: 24px;

      input {
        margin-bottom: 0;
      }

      span {
        position: absolute;
        font-size: 12px;
        right: 12px;
        color: #0382c9;
      }
    }

    .title {
      border: none;
      font-size: 20px;
      margin: 0 10px;
      cursor: pointer;
      border-bottom: 2px solid transparent;
      padding: 6px;
      background: transparent;
      margin-bottom: 24px;
      border-color: #0382c9;
      color: #000;
    }

    .input {
      width: 100%;
      border: 1px solid #eeefee;
      padding: 12px;
      outline: none;
      border-radius: 0;
      border-radius: 4px;
      font-size: 16px;
      margin-bottom: 24px;
    }

    label {
      color: #757575;
      display: flex;
      font-size: 14px;
      text-align: left;

      span {
        padding-left: 4px;
        line-height: 1.2;
      }
    }

    .btn {
      width: 100%;
      background: #2f83e1;
      padding: 12px;
      color: #fff;
      border: none;
      border-radius: 6px;
      margin-top: 2rem;
      font-size: 16px;
      cursor: pointer;
    }

    .footer {
      display: flex;
      justify-content: space-between;
      color: #000;
      margin-top: 2rem;

      a {
        color: #2f83e1;
      }
    }
  }
}
</style>
