<template>
  <div class="orderView">
    <h1 class="title">{{ $t("order.orderCenter") }}</h1>

    <div v-if="tableList.predictions">
      <div
        class="list"
        v-for="(item, index) in tableList.predictions"
        :key="index"
      >
        <h1>{{ $t("order.orderno") }}:</h1>

        <div class="row">
          <span>{{ $t("order.orderTime") }}</span>
          <span>{{ handleTime(item.created_at) }}</span>
        </div>

        <div class="row">
          <span>{{ $t("order.predictOpenLotteryTime") }}</span>
          <span>{{ item.closed_at ? handleTime(item.closed_at) : "" }}</span>
        </div>

        <div class="row">
          <span>{{ $t("order.tradingPair") }}</span>
          <span>{{ getCoinName(item.market_id) }}</span>
        </div>

        <div class="row">
          <span>{{ $t("order.type") }}</span>
          <span>{{ item.lock_price }}</span>
        </div>

        <div class="row">
          <span>{{ $t("order.predictDirection") }}</span>
          <span>{{
            item.position === "long" ? $t("kline.buyUp") : $t("kline.buyDown")
          }}</span>
        </div>

        <div class="row">
          <span>{{ $t("order.predictPrice") }}</span>
          <span>{{ item.volume }}</span>
        </div>
      </div>
      <el-pagination
        layout="prev, pager, next"
        :page-count="totalPage"
        @current-change="changePage"
        :pager-count="5"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getPredictions, getMarkets } from "@/services/Api.js";
import moment from "moment";

export default {
  name: "OrderView",

  mounted() {
    this.getData();
  },

  data() {
    return {
      tableList: [],
      markets: [],
      totalPage: 1,
      currentPage: 1,
    };
  },

  methods: {
    getData() {
      var senddata = {
        pages: this.currentPage,
        count: 10,
      };
      const queryString = Object.keys(senddata)
        .map((key) => key + "=" + senddata[key])
        .join("&");

      getPredictions(queryString)
        .then((res) => {
          const { success, data } = res.data;

          if (success) {
            this.tableList = data;
            this.totalPage = data.total_pages;
          }
        })
        .catch((err) => console.log(err));

      getMarkets()
        .then((res) => {
          const { data } = res;
          this.markets = data;
        })
        .catch((err) => console.log(err));
    },
    changePage(value) {
      this.currentPage = value;
      this.getData();
    },
    handleTime(time) {
      return moment(time).format("YYYY-MM-DD HH:mm:ss");
    },
    getCoinName(value) {
      for (let i = 0; i < this.markets.length; i++) {
        if (this.markets[i].id === value) return this.markets[i].name;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.orderView {
  height: 100vh;
  overflow-y: scroll;

  .title {
    font-size: 21px;
    font-weight: bold;
    margin-top: 1rem;
  }

  .list {
    width: 100%;
    background-color: #22222d;
    width: calc(100% - 2rem);
    margin: 0 auto;
    margin-bottom: 1rem;
    padding: 1rem;
    border-radius: 20px;

    &:first-of-type {
      margin-top: 1rem;
    }

    h1 {
      font-size: 16px;
      margin-bottom: 1rem;
      text-align: left;
    }

    .row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 8px;

      span {
        font-size: 14px;

        &:first-of-type {
          color: #c6c6c7;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.btn-prev {
  background-color: unset !important;
  color: #c0c4cc !important;
}
.btn-next {
  background-color: unset !important;
  color: #c0c4cc !important;
}
.el-pager li {
  background: unset !important;
  color: #c0c4cc;
}
.el-icon-more {
  color: #c0c4cc !important;
}
</style>
