export default {
  homeInfoTitle1: "优质项目资产",
  homeInfoText1:
    "详细而严谨的项目在线审核流程，每个项目背后都有实体行业，确保推荐给用户的项目具有可行性和价值增长潜力",
  homeInfoTitle2: "规范化运营管理",
  homeInfoText2:
    "具有 AUSTRAC 许可证的受监管数字股票交易平台，与第三方机构提供全程辅导和督导",
  homeInfoTitle3: "雄厚的资本实力",
  homeInfoText3:
    "详细而严谨的项目在线审核流程，每个项目背后都有实体行业，确保推荐给用户的项目具有可行性和价值增长潜力",
  mtfTokenWhitePaper: "MTF Token 白皮书",
  latestPrice: "最新价",
  upsAndDowns: "涨跌",
  lowPrice: "最低价",
  volume2: "成交量",
  orderPrice: "成交额",
  charge: "充值",
  withdraw: "提币",
  orderlist: "订单",
  contactService: "客服",
  loginFirst: "请先登入",
  marqueeText1: "邀请活动火爆上线，惊喜福利享不停",
};
