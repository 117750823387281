<template>
  <div
    class="CoinInfoComponent"
    :key="marketIndex"
    v-loading="loading"
    element-loading-background="#00000080"
  >
    <div class="left" v-if="marketIndex != null">
      <select
        class="select"
        v-model="select_index"
        :placeholder="$t('kline.pleaseSelect')"
        @change="clickOptionFun(select_index)"
      >
        <option
          v-for="(item, index) in coinList"
          :key="index"
          :label="item.name"
          :value="index"
        >
          {{ item.name }}
        </option>
      </select>
      <div
        class="vlaue"
        :class="[{ red: latestChangeRed < 0 }, { green: latestChangeRed > 0 }]"
        v-if="marketIndex != null"
      >
        {{ coinList[marketIndex].ticker.last }}
        <font-awesome-icon
          icon="fa-solid fa-arrow-down"
          v-if="latestChangeRed < 0"
        />
        <font-awesome-icon
          icon="fa-solid fa-arrow-up"
          v-if="latestChangeRed > 0"
        />
      </div>
    </div>
    <div class="right" v-if="marketIndex != null">
      <div class="lebel">
        <div class="title">24h {{ $t("kline.upsAndDowns") }}</div>
        <div
          class="vlaue green"
          :class="[{ red: gainFun(coinList[marketIndex]) < 0 }]"
          v-if="marketIndex != null"
        >
          {{ gainFun(coinList[marketIndex]) }}
        </div>
      </div>
      <div class="lebel">
        <div class="title">24h {{ $t("kline.highPrice") }}</div>
        <div class="vlaue" v-if="marketIndex != null">
          {{ coinList[marketIndex].ticker.high }}
        </div>
      </div>
      <div class="lebel">
        <div class="title">24h {{ $t("kline.lowPrice") }}</div>
        <div class="vlaue" v-if="marketIndex != null">
          {{ coinList[marketIndex].ticker.low }}
        </div>
      </div>
      <div class="lebel">
        <div class="title">24h {{ $t("kline.volume2") }}</div>
        <div class="vlaue" v-if="marketIndex != null">
          {{ coinList[marketIndex].ticker.volume }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getKline } from "@/services/Api.js";
import moment from "moment";

export default {
  inject: ["reload"],
  name: "CoinInfoComponent",
  props: {
    coinList: { Object },
    marketIndex: { String },
  },
  components: {},
  watch: {
    // select_index(value) {
    //   console.log(value, this.coinList[value].name);
    //   this.$router.push(
    //     `/marketkline/${this.coinList[value].name.replace("/", "%2F")}`
    //   );
    //   this.reload();
    // },
    marketIndex(value) {
      console.log(value);
      this.select_index = value;
      this.loading = false;
    },
  },
  data() {
    return {
      select_index: this.marketIndex,
      loading: true,
      latestChangeRed: 0,
    };
  },
  mounted() {
    // console.log(this.marketIndex);
    this.getKlineData(1);
  },
  methods: {
    gainFun(item) {
      return item.ticker.open == 0
        ? 0
        : parseFloat(
            ((item.ticker.last - item.ticker.open) / item.ticker.open).toFixed(
              4
            )
          );
    },
    clickOptionFun(value) {
      console.log(this.marketIndex);
      console.log(value);
      this.$router.push(
        `/marketkline/${this.coinList[value].name.replace("/", "%2F")}`
      );
      this.reload();
    },
    getKlineData(period) {
      var senddata = {
        market: this.$route.params.marketName,
        locale: "zh-CN",
        period: period,
        timestamp: moment().subtract(15, "d").format("X"),
        end: moment().format("X"),
      };

      const queryString = Object.keys(senddata)
        .map((key) => key + "=" + senddata[key])
        .join("&");

      getKline(queryString)
        .then((res) => {
          console.log(res);
          this.latestChangeRed =
            parseFloat(res.data[res.data.length - 1][4]) -
            parseFloat(res.data[res.data.length - 2][4]);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/globalstyle.scss";
.CoinInfoComponent {
  @include flex-set();
  justify-content: space-between;
  padding: 10px 20px;
  background: #141722;
  height: 100px;
  .left {
    // width: 50%;
    text-align: left;
    line-height: 30px;
    .select {
      color: #ffffff;
      font-size: 16px;
      font-weight: 400;
      background-color: #141722;
      border: unset;
      option {
        background-color: #2a2e39;
        color: #cccccc;
      }
    }
    select {
      outline: none;
    }
    .vlaue {
      font-size: 14px;
      font-weight: 400;
      margin-left: 5px;
    }
  }
  .right {
    width: 55%;
    max-width: 200px;
    .lebel {
      @include flex-set();
      justify-content: space-between;
      text-align: right;
      font-weight: 400;
      font-size: 12px;
      margin: 5px 0;
    }
  }
}
.green {
  color: #29a59d;
}
.red {
  color: #f96464;
}
</style>
