export default {
  title: "Withdrawal",
  enterWalletAddressAndPrice:
    "Please enter your wallet address and withdrawal amount,",
  contactForServiceAfterSend:
    "Please contact customer service after confirming delivery",
  coinWalletAddress: "Coin Wallet Address",
  enterWalletAddress: "Please enter the wallet address",
  withdrawPrice: "Withdrawal Amount",
  enterWithdrawPrice: "Please enter the withdrawal amount",
  send: "Send",
  inputIncorrect: "There are fields that have not been filled in",
  contactForService: "Please contact customer service",
  confirm: "Confirm",
};
