<template>
  <div class="drawer-btn-group">
    <div class="user_wrap" v-if="login">
      <el-avatar
        class="avatar"
        icon="el-icon-user-solid"
        v-if="login"
      ></el-avatar>

      <div class="user">{{ userData.email }}</div>

      <div class="balance">
        {{ $t("navbar.balance") }}:<span>{{ uadtBalance }}</span>
      </div>
    </div>

    <div class="btn_wrap" v-if="!login">
      <div class="btn" @click="jump('login')">
        {{ $t("navbar.login") }}
      </div>
      <div class="btn" @click="jump('register')">
        {{ $t("navbar.register") }}
      </div>
    </div>

    <div class="drawer-btn hover" @click="jump('charge')" v-if="login">
      <img :src="require('../../assets/icons/in.svg')" alt="icon" />
      <span class="text">
        {{ $t("navbar.charge") }}
      </span>
    </div>
    <div class="drawer-btn hover" @click="jump('withdraw')" v-if="login">
      <img :src="require('../../assets/icons/out.svg')" alt="icon" />
      <span class="text">
        {{ $t("navbar.withDraw") }}
      </span>
    </div>
    <div class="drawer-btn hover" @click="jump('order')" v-if="login">
      <img :src="require('../../assets/icons/order.svg')" alt="icon" />
      <span class="text">
        {{ $t("navbar.orderCenter") }}
      </span>
    </div>
    <div class="drawer-btn hover">
      <img :src="require('../../assets/icons/News.svg')" alt="icon" />
      <span class="text">
        {{ $t("navbar.newsCenter") }}
      </span>
    </div>
    <div class="drawer-btn hover">
      <img :src="require('../../assets/icons/cs.svg')" alt="icon" />

      <div>
        <span class="text">{{ $t("navbar.customerServiceEmail") }}:</span>
        <span class="blue">{{ "mtftoken@gmail.com" }}</span>
      </div>
    </div>
    <div class="drawer-btn hover">
      <img :src="require('../../assets/icons/News Copy.svg')" alt="icon" />
      <select class="hover" @change="changeLang($event.target.value)">
        <option class="option" value="zht">
          {{ $t("navbar.zht") }}
        </option>
        <option class="option" value="zhs">
          {{ $t("navbar.zhs") }}
        </option>
        <option class="option" value="en">
          {{ $t("navbar.en") }}
        </option>
      </select>
    </div>
    <span class="login_out hover" v-if="login" @click="loginoutFun">
      {{ $t("navbar.loginOut") }}
    </span>
  </div>
</template>

<script>
import { getUser } from "@/services/Api.js";
import Cookie from "js-cookie";

export default {
  inject: ["reload"],
  name: "DrawerComponent",
  props: {
    loginStatus: { Boolean },
    account: { String },
    uadtBalance: { Number },
  },
  data() {
    return {
      route: this.$route.name,
      login: Cookie.get("sn") != null,
      userData: {},
    };
  },
  watch: {
    $route(to) {
      // console.log(to.name);
      this.route = to.name;
    },
  },
  mounted() {
    if (this.login) {
      getUser()
        .then((res) => {
          const { data, success } = res.data;
          if (success) {
            this.userData = data;
          }
        })
        .catch((err) => console.log(err));
    }
  },
  methods: {
    handleClose() {
      this.$emit("handleClose");
    },
    jump(type) {
      this.handleClose();
      this.$router.push({ name: type }).catch(() => {});
    },
    loginoutFun() {
      // getLogout()
      //   .then((res) => {
      //     console.log(res);
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });

      this.$message({
        message: this.$t("navbar.logoutSuccess"),
        type: "success",
        offset: 70,
      });
      Cookie.remove("sn");

      this.$router.push("/").catch(() => {});
      this.reload();
    },
    open() {
      window.open("https://mtftoken.com/news/zh-cn.html#last_news");
    },
    changeLang(lang) {
      this.$i18n.locale = lang;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/globalstyle.scss";

.drawer-btn-group {
  width: 100%;
  height: 100%;
  position: relative;

  .avatar {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
  }

  .user {
    margin: 0.5rem 0;
  }

  .btn_wrap {
    display: flex;
    user-select: none;
    flex-direction: column;
    padding: 2rem 1.25rem;

    .btn {
      padding: 0.5rem 1rem;
      text-align: center;
      font-size: 14px;
      font-weight: 600;
      border-radius: 0.25rem;
      border-width: 1px;
      color: #fff;
      border: 1px solid #2f83e1;

      &:last-of-type {
        margin-top: 1rem;
        background-color: #2f83e1;
        color: #000;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  .login_out {
    font-size: 14px;
    color: #ffffff;
    position: absolute;
    left: 2rem;
    bottom: 2rem;
    &:hover {
      cursor: pointer;
    }
  }

  .drawer-btn {
    width: 100%;
    line-height: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @include font-set($black, 14px, 700, sans-serif);
    margin-bottom: 8px;
    padding-left: 16px;

    img {
      height: 30px;
    }

    span {
      padding-left: 16px;
      @include font-set($white, 14px);
      width: 100%;
      text-align: left;
      display: flex;
      align-items: center;
      height: 100%;
      &.red {
        color: red;
      }
      &.blue {
        color: #2f83e1;
        line-height: 1;
      }
    }

    .text,
    select {
      @include font-set($white, 14px);
      background-color: rgb(41, 45, 57);
    }

    select {
      border: unset;
      width: 90%;
      outline: none;
      height: 36px;
      margin-top: 4px;
      margin-left: 8px;
    }

    &:hover {
      .text {
        color: #2f83e1;
      }
    }
  }
  .hover {
    &:hover {
      cursor: pointer;
      color: #2f83e1;
    }
    .option {
      color: $white;
    }
  }
  .selectedClass {
    color: #2f83e1;
  }

  .account {
    height: 30px;
    color: #888;
  }
  .topic {
    @include font-set($black, 16px, 900, sans-serif);
    color: #888;
  }
  .btn-box {
    width: 100%;
    position: absolute;
    bottom: 75px;
    @include flex-set();
    .logout-btn {
      width: 75%;
      height: 38px;
      background: #2f83e1;
      border-radius: 8px;
      @include font-set(#fff, 14px, 500, sans-serif);
      text-align: center;
      @include hover-set();
      &:hover {
        cursor: pointer;
        background: #fff;
        border: 1px #2f83e1 solid;
        box-shadow: 0px 0px 10px 0px #2f83e180;
        color: #2f83e1;
      }
    }
  }
}

.user_wrap {
  margin-bottom: 15px;
}

::v-deep .el-message {
  min-width: 200px !important;
}
</style>
