import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "@/views/HomeView.vue";
import LoginView from "@/views/LoginView.vue";
import RegisterView from "@/views/RegisterView";
import ForgotPassword from "@/views/ForgotPassword";
import MarketKlineView from "@/views/MarketKlineView.vue";
import Chargeview from "@/views/Chargeview.vue";
import WithdrawView from "@/views/WithdrawView.vue";
import OrderView from "@/views/OrderView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
  {
    path: "/register",
    name: "register",
    component: RegisterView,
  },
  {
    path: "/forgotPassword",
    name: "forgotPassword",
    component: ForgotPassword,
  },
  {
    path: "/charge",
    name: "charge",
    component: Chargeview,
  },
  {
    path: "/withdraw",
    name: "withdraw",
    component: WithdrawView,
  },
  {
    path: "/order",
    name: "order",
    component: OrderView,
  },
  {
    path: "/marketkline/:marketName",
    name: "marketkline",
    component: MarketKlineView,
  },
  {
    path: "*",
    redirect: {
      name: "home",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
