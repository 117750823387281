<template>
  <el-carousel
    class="TopSwiperComponent"
    height="50vw"
    indicator-position="none"
  >
    <el-carousel-item v-for="(item, index) in banner_data" :key="index">
      <img class="img" :src="item" />
    </el-carousel-item>
  </el-carousel>
</template>
<script>
export default {
  name: "TopSwiperComponent",
  props: {},
  components: {},
  data() {
    return {
      banner_data: [
        require("@/components/Home/banner/1_m.jpg"),
        require("@/components/Home/banner/2_m.jpg"),
        require("@/components/Home/banner/3_m.jpg"),
      ],
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
// @import "@/assets/style/globalstyle.scss";
.TopSwiperComponent {
  max-height: 350px;
  ::v-deep .el-carousel__container {
    max-height: 350px;
    overflow: hidden;
  }
  .el-carousel__item h3 {
    color: #000000;
    font-size: 14px;
    opacity: 0.75;
    line-height: 150px;
    margin: 0;
  }

  .el-carousel__item {
    background-color: #000000;
  }

  .img {
    width: 100%;
  }
}
</style>
