export default {
  title: "提幣",
  enterWalletAddressAndPrice: "請輸入您的幣包地址與提款金額,",
  contactForServiceAfterSend: "確認送出後請與客服聯繫",
  coinWalletAddress: "幣包地址",
  enterWalletAddress: "請輸入幣包地址",
  withdrawPrice: "提款金額",
  enterWithdrawPrice: "請輸入提款金額",
  send: "送出",
  inputIncorrect: "有欄位尚未填寫",
  contactForService: "請與客服聯繫",
  confirm: "確認",
};
