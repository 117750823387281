<template>
  <div class="btnNav">
    <!-- <div
      class="nav"
      v-for="({ img, val, link }, index) in list"
      :key="`nav${index}`"
      @click="handleClick(link)"
    >
      <img :src="img" alt="index" />
      <span>{{ val }}</span>
    </div> -->

    <div class="nav" @click="handleClick(list[0]['link'])">
      <img :src="list[0]['img']" alt="index" />
      <span>{{ $t("home.charge") }}</span>
    </div>
    <div class="nav" @click="handleClick(list[1]['link'])">
      <img :src="list[1]['img']" alt="index" />
      <span>{{ $t("home.withdraw") }}</span>
    </div>
    <div class="nav" @click="handleClick(list[2]['link'])">
      <img :src="list[2]['img']" alt="index" />
      <span>{{ $t("home.orderlist") }}</span>
    </div>
    <div class="nav" @click="handleClick(list[3]['link'])">
      <img :src="list[3]['img']" alt="index" />
      <span>{{ $t("home.contactService") }}</span>
    </div>
  </div>
</template>

<script>
import Cookie from "js-cookie";

export default {
  name: "BtnNav",

  data() {
    return {
      login: Cookie.get("sn") != null,
      list: [
        {
          img: require("../../../assets/icons/in.svg"),
          val: this.$t("home.charge"),
          link: "/charge",
        },
        {
          img: require("../../../assets/icons/out.svg"),
          val: this.$t("home.withdraw"),
          link: "/withdraw",
        },
        {
          img: require("../../../assets/icons/order.svg"),
          val: this.$t("home.orderlist"),
          link: "/order",
        },
        {
          img: require("../../../assets/icons/cs.svg"),
          val: this.$t("home.contactService"),
        },
      ],
    };
  },

  methods: {
    handleClick(url) {
      if (!this.login && url) {
        this.$message({
          message: this.$t("home.loginFirst"),
          type: "warning",
          offset: 70,
        });
      } else if (!url) {
        this.$alert(
          `${this.$t("navbar.customerServiceEmail")}: mtftoken@gmail.com`,
          this.$t("footer.contact"),
          {
            confirmButtonText: this.$t("kline.confirm"),
            customClass: "alert",
            dangerouslyUseHTMLString: true,
          }
        );
      } else {
        this.$router.push(url);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.btnNav {
  display: flex;
  justify-content: space-between;
  background-color: #22222d;
  border-radius: 15px;
  padding: 8px 36px;
  width: calc(100% - 2rem);
  margin: 0 auto;
  text-align: center;

  .nav {
    display: flex;
    flex-direction: column;
    max-width: 44px;

    img {
      margin-bottom: 8px;
    }

    span {
      font-size: 14px;
      color: #cdd1d6;
    }

    &:hover {
      cursor: pointer;
    }
  }
}
</style>
