<template>
  <div id="app">
    <NavbarComponent class="NavbarComponent" v-if="isRouterAlive" />
    <div class="bg" v-if="showSideImage">
      <div class="leftbox">
        <img src="@/assets/images/Img_Left.png" class="image" />
      </div>
    </div>
    <router-view ref="router_view" class="router_view" v-if="isRouterAlive" />
    <div class="bg right" v-if="showSideImage">
      <div class="rightbox">
        <img src="@/assets/images/Img_Right.png" class="whitebookimg" />
        <div class="button" @click="downloadWhitePaper">
          {{ $t("home.mtfTokenWhitePaper") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavbarComponent from "@/components/Navbar/NavbarComponent.vue";

export default {
  name: "App",
  components: {
    NavbarComponent,
  },
  provide() {
    return {
      reload: this.reload,
      scrollTop: this.scrollTop,
    };
  },
  data() {
    return {
      isRouterAlive: true,
      showSideImage: true,
      eventListener: null,
      currentWidth: 0,
    };
  },
  watch: {
    currentWidth(newValue) {
      if (newValue < 1200) {
        this.showSideImage = false;
      } else {
        this.showSideImage = true;
      }
    },
    $route() {
      this.scrollTop();
    },
  },
  mounted() {
    this.getCurrentWidth();
    this.eventListener = window.addEventListener(
      "resize",
      this.getCurrentWidth
    );
  },
  methods: {
    reload() {
      console.log("reload");
      this.isRouterAlive = false;
      this.$nextTick(() => (this.isRouterAlive = true));
    },
    getCurrentWidth() {
      this.currentWidth = window.innerWidth;
    },
    downloadWhitePaper() {
      window.open("/MTFWhitePaper.pdf");
    },
    scrollTop() {
      this.$refs.router_view.scrollTop = 0;
    },
  },
  beforeDestory() {
    window.removeEventListener(this.eventListener);
  },
};
</script>

<style lang="scss">
@import "@/assets/style/globalstyle.scss";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #ffffff;
  background: #000000;
  display: flex;
  flex: 1;
  overflow: hidden;
  * {
    font-family: Avenir, Helvetica, Arial, sans-serif;
  }
  .bg {
    height: calc(100vh - 64px);
    max-width: 33.3%;
    margin-top: 64px;
    width: 33.3%;
    background-image: url("~@/assets/images/mtftokenbg.png");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: end;
    .leftbox {
      width: 200px;
      height: calc(100% - 64px);
      margin-right: 20px;
      margin-top: 10px;
      .image {
        height: 100%;
        max-height: 900px;
      }
    }
    .rightbox {
      width: 240px;
      height: calc(100% - 64px);
      margin-left: 20px;
      margin-top: 10px;
      .whitebookimg {
        // width: 100%;
        max-height: 745px;
        height: 100%;
      }
      .button {
        height: 45px;
        max-width: 200px;
        background: #3584dd;
        margin: 0 auto;
        margin-top: 15px;
        line-height: 45px;
        border-radius: 45px;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
  .right {
    justify-content: start;
  }
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.router_view {
  padding-top: 64px;
  background: #292d39;
  max-width: 660px;
  margin: 0 auto;
  min-height: 100vh;
  width: 100%;
  overflow-y: scroll;
  height: 100vh;
}

.alert {
  margin: 40vh 0;
  max-width: 90vw;
}
</style>
