export default {
  orderCenter: "订单中心",
  orderno: "订单号",
  orderTime: "订单时间",
  predictOpenLotteryTime: "预测开奖时间",
  tradingPair: "交易对",
  type: "仓位",
  predictDirection: "预测方向",
  predictPrice: "预测金额",
};
