<template>
  <div class="CoinListComponent" v-if="coinList">
    <router-link
      class="coin_box"
      :to="{
        name: 'marketkline',
        params: {
          marketName: coin.name,
        },
      }"
      v-for="(coin, index) in coinList"
      :key="`coin${index}`"
    >
      <h1>{{ coin.name }}</h1>

      <div class="row">
        <span class="span">{{ $t("home.latestPrice") }}</span>
        <span class="span">{{ coin.ticker.last }}</span>
      </div>
      <div class="row">
        <span class="span">{{ `24h ${$t("home.upsAndDowns")}` }}</span>
        <span class="span green" :class="[{ red: gainFun(coin) < 0 }]">
          <font-awesome-icon
            icon="fa-solid fa-arrow-down"
            v-if="gainFun(coin) < 0"
          />
          <font-awesome-icon icon="fa-solid fa-arrow-up" v-else />
          {{ gainFun(coin) }}
        </span>
      </div>
      <div class="row">
        <span class="span">{{ `24h ${$t("home.lowPrice")}` }}</span>
        <span class="span">{{ coin.ticker.low }}</span>
      </div>
      <div class="row">
        <span class="span">{{ `24h ${$t("home.volume2")}` }}</span>
        <span class="span">{{
          parseFloat(coin.ticker.turnover).toFixed(4)
        }}</span>
      </div>
      <div class="row">
        <span class="span">{{ `24h ${$t("home.orderPrice")}` }}</span>
        <span class="span">{{ coin.ticker.volume }}</span>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "CoinListComponent",
  props: { coinList: { Object } },
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    gainFun(item) {
      const {
        ticker: { last, open },
      } = item;

      const val = ((last - open) / open).toFixed(4);

      return open == 0 ? 0 : parseFloat(val);
    },
  },
};
</script>

<style lang="scss" scoped>
.CoinListComponent {
  padding: 1rem 0;
  border-radius: 15px 15px 0 0;
  width: calc(100% - 2rem);
  margin: 0 auto;

  .coin_box {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    width: 100%;
    color: #ffffff;
    border-bottom: 1px solid #88888820;
    background-color: #22222d;
    padding: 1.25rem;
    margin-bottom: 12px;
    border-radius: 20px;

    h1 {
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 1rem;
    }
    .row {
      display: flex;
      margin-top: 8px;
      justify-content: space-between;
      span {
        font-size: 14px;
        &:first-of-type {
          color: #c6c6c7;
        }
      }
    }

    .green {
      color: #29a59d;
    }
    .red {
      color: #f96464;
    }
  }
}
</style>
