<template>
  <div class="traderecord">
    <div class="titlebox">
      <div class="title time">
        <span class="left">{{ $t("kline.time") }}</span>
      </div>
      <div class="title price">
        <span>{{ $t("kline.price") }}({{ priceCoin }})</span>
      </div>
      <div class="title count">
        <span class="right">{{ $t("kline.volume") }}({{ countCoin }})</span>
      </div>
    </div>
    <div
      class="tradedata"
      v-for="(current, index) in tradeRecordData"
      :key="index"
    >
      <div class="data left time">
        <span class="left">{{ current.time }}</span>
      </div>
      <div class="data center price">
        <span>{{ current.price }}</span>
      </div>
      <div class="data right count">
        <span class="right">{{ current.count }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { getTradeList } from "@/services/Api.js";
import moment from "moment";

export default {
  name: "TradeRecord",
  data() {
    return {
      tradeRecordData: [
        // {
        //   side: "sell",
        //   time: "18:48:28",
        //   price: "20567.11",
        //   count: "0.017500",
        // },
        // {
        //   side: "buy",
        //   time: "18:48:28",
        //   price: "20567.11",
        //   count: "0.017500",
        // },
      ],
      countCoin: "",
      priceCoin: "",
      timer: null,
      limit: 30,
    };
  },
  watch: {
    "$route.params.marketName": {
      handler: function (newValue) {
        console.log(newValue);
        let temp = newValue.split("/");
        this.countCoin = temp[0];
        this.priceCoin = temp[1];
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.marketName = this.$route.params.marketName;
    this.getTradeData();
    this.startTimer();
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    getTradeData() {
      var senddata = {
        market: this.marketName,
        limit: this.limit,
      };
      const queryString = Object.keys(senddata)
        .map((key) => key + "=" + senddata[key])
        .join("&");

      getTradeList(queryString)
        .then((res) => {
          if (res.data.error) {
            return;
          }
          var datas = [];
          for (let i = 0; i < res.data.length; i++) {
            var temp = {
              time: moment(res.data[i].created_at).format(
                "YYYY-MM-DD HH:mm:ss"
              ),
              price: res.data[i].price,
              count: res.data[i].volume,
              side: res.data[i].side,
            };
            datas.push(temp);
          }
          this.tradeRecordData = datas;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    startTimer() {
      this.timer = setInterval(this.getTradeData, 10000);
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/style/globalstyle.scss";

.traderecord {
  width: 100%;
  @include flex-set(wrap, center, unset);
  margin-top: 10px;
  .titlebox {
    width: 90%;
    @include flex-set(unset, space-between);
    line-height: 30px;
    font-weight: bold;
    .title {
      width: 33.3%;
      @include flex-set();
      .left {
        width: 100%;
        text-align: left;
      }
      .right {
        width: 100%;
        text-align: right;
      }
    }
  }
  .tradedata {
    width: 90%;
    @include flex-set(unset, space-between);
    line-height: 28px;
    .data {
      width: 33.3%;
      @include flex-set();
      .left {
        width: 100%;
        text-align: left;
      }
      .center {
        width: 100%;
        text-align: center;
      }
      .right {
        width: 100%;
        text-align: right;
      }
    }
  }
}
</style>
