<template>
  <div class="BuyAndSellComponent">
    <div class="button_box">
      <div class="green button" @click="clickBtnFun(0)">
        {{ $t("kline.buyUp") }}
      </div>
    </div>
    <div class="button_box">
      <div class="red button" @click="clickBtnFun(1)">
        {{ $t("kline.buyDown") }}
      </div>
    </div>

    <div class="mask" v-if="showPopout" @click="showPopout = false">
      <div
        class="popout"
        @click.stop
        v-loading="loading"
        element-loading-background="#00000080"
      >
        <div class="label">
          <div class="left">{{ $t("kline.sendOrder") }}</div>
          <div class="right">
            {{ $t("kline.accountBalance") }}
            <span class="bold">{{ uadtBalance }}</span>
            USDT
          </div>
        </div>

        <div class="buy_type">
          <div
            class="btn left"
            :class="[{ green: betForm.position == 'long' }]"
            @click="betForm.position = 'long'"
          >
            {{ $t("kline.buyUp") }}
          </div>
          <div
            class="btn"
            :class="[{ red: betForm.position == 'short' }]"
            @click="betForm.position = 'short'"
          >
            {{ $t("kline.buyDown") }}
          </div>
        </div>

        <div class="label border_bottom">
          <div class="left">{{ $t("kline.orderTime") }}</div>
          <div class="right bold">{{ time.toLocaleString() }}</div>
        </div>
        <div class="label border_bottom">
          <div class="left">{{ $t("kline.type") }}</div>
          <div class="right bold">{{ coinData.ticker.last }}</div>
        </div>
        <div
          class="label border_bottom"
          :class="[{ red_border_bottom: amountTooBigMsg }]"
        >
          <div class="left">{{ $t("kline.price2") }}</div>
          <input
            class="amount"
            v-model="betForm.volume"
            :placeholder="$t('kline.pleaseInputPrice')"
            type="number"
          />
          <div class="right">
            <!-- <span class="bold">{{ amountList[formData.amount] || 0 }}</span> -->
            USDT
          </div>
        </div>
        <div class="msg">
          {{ amountTooBigMsg ? $t("kline.insufficientBalance") : "" }}
        </div>

        <div class="amount_box">
          <div
            class="amount_btn"
            v-for="(item, index) in amountList"
            :key="index"
            @click="addAmount(item)"
          >
            {{ item }}
          </div>
          <div class="amount_btn" @click="betForm.volume = 0">
            {{ $t("kline.reset") }}
          </div>
        </div>

        <div class="button_box">
          <div class="green button" @click="subnitFun">
            {{ $t("kline.confirmOrder") }}
          </div>
        </div>
        <div class="button_box">
          <div class="red button" @click="showPopout = false">
            {{ $t("kline.cancel") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  postPredictions,
  getPredictions,
  getAccounts,
} from "@/services/Api.js";
// import { getPredictions } from "@/services/Api.js";
import Cookie from "js-cookie";
import moment from "moment";

export default {
  name: "BuyAndSellComponent",
  props: {
    marketId: { String },
    coinData: { Object },
  },
  watch: {
    "betForm.volume"(value) {
      console.log(value);
      this.amountTooBigMsg = value > this.uadtBalance;
    },
  },
  components: {},
  data() {
    return {
      loading: false,
      showPopout: false,
      amountList: [1, 10, 50, 100, 500, 1000],
      betForm: {
        market_id: 1,
        position: null, //预测方向涨(long)或跌(short)
        volume: 0,
        lock_price: 0,
      },
      time: null,
      showDialog: null,
      uadtBalance: 0,
      amountTooBigMsg: false,
    };
  },
  mounted() {
    this.getAccountsFun();
  },
  methods: {
    clickBtnFun(type) {
      if (Cookie.get("sn") ? true : false) {
        this.time = new Date();
        this.betForm = {
          market_id: this.marketId,
          position: type == 0 ? "long" : "short", //预测方向涨(long)或跌(short)
          volume: 0,
          lock_price: this.coinData.ticker.last,
        };
        this.showPopout = true;
        this.loading = false;
        this.getAccountsFun();
      } else {
        this.$message({
          message: this.$t("kline.loginBeforeOrder"),
          type: "warning",
          offset: 70,
        });
      }
    },
    subnitFun() {
      // alert("模擬下單成功");

      if (this.betForm.volume <= 0) {
        this.$message({
          message: this.$t("kline.orderPriceMustGreaterThan0"),
          type: "warning",
          offset: 70,
        });
        return;
      }

      if (this.amountTooBigMsg) {
        this.$message({
          message: this.$t("kline.insufficientBalance"),
          type: "warning",
          offset: 70,
        });
        return;
      }

      postPredictions(this.betForm)
        .then((res) => {
          console.log(res);
          if (res.data.success) {
            this.loading = true;
            setTimeout(() => {
              this.getPredictionsFun();
            }, 1000);
          } else {
            this.$message({
              message: res.data.errors[0].message,
              type: "error",
              offset: 70,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.$message({
            message: this.$t("kline.temporaryCanNotOrder"),
            type: "error",
            offset: 70,
          });
        });
    },
    getPredictionsFun() {
      console.log("getPredictionsFun");

      var senddata = { pages: 1, count: 10000000 };
      const queryString = Object.keys(senddata)
        .map((key) => key + "=" + senddata[key])
        .join("&");

      getPredictions(queryString)
        .then((res) => {
          console.log(res);
          if (res.data.success) {
            const new_data =
              res.data.data.predictions[res.data.data.predictions.length - 1];
            const html = `
            <div class="flex"><div>${this.$t("kline.orderID")}</div><div>
            ${new_data.sn != null ? new_data.sn : ""}
            </div></div>
            <div class="flex"><div>${this.$t("kline.tradingPair")}</div><div>
            ${this.coinData.name}
            </div></div>
            <div class="flex"><div>
            ${this.$t("kline.predictDirection")}
            </div><div>
            ${
              new_data.position == "long"
                ? this.$t("kline.buyUp")
                : this.$t("kline.buyDown")
            }
            </div></div>
            <div class="flex"><div>${this.$t("kline.predictPrice")}</div><div>
            ${new_data.volume}
            </div></div>
            <div class="flex"><div>${this.$t("kline.type")}</div><div>
            ${new_data.lock_price}
            </div></div>
            <div class="flex"><div>${this.$t("kline.orderTime")}</div><div>
            ${moment(new_data.created_at).format("YYYY-MM-DD HH:mm:ss")}
            </div></div>
            <div class="flex"><div>${this.$t("kline.openTime")}</div><div>
            ${moment(new_data.created_at)
              .add(48, "h")
              .format("YYYY-MM-DD HH:mm:ss")}
            </div></div>
            `;

            this.$alert(html, this.$t("kline.orderSuccess"), {
              confirmButtonText: this.$t("kline.confirm"),
              customClass: "alert",
              dangerouslyUseHTMLString: true,
            });

            this.showPopout = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getAccountsFun() {
      getAccounts()
        .then((res) => {
          console.log(res);
          if (res.data.success) {
            res.data.data.map((item) =>
              item.code === "USDT"
                ? (this.uadtBalance = parseFloat(item.balance))
                : null
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // checkBalanceFun() {
    //   this.amountTooBigMsg = this.betForm.volume > this.uadtBalance;
    // },
    addAmount(value) {
      this.betForm.volume = parseFloat(this.betForm.volume) + value;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/globalstyle.scss";

.BuyAndSellComponent {
  @include flex-set();
  width: 100vw;
  max-width: 660px;
  // margin: 10px auto;
  padding: 10px;
  position: fixed;
  bottom: 0;
  z-index: 100;
  background: #2a2e39;
  .button_box {
    width: 50%;
    padding: 5px;
    margin-bottom: 10px;
  }
  .button {
    height: 38px;
    border-radius: 4px;
    line-height: 38px;
    text-align: center;
    &:hover {
      cursor: pointer;
      transform: scale(1.05);
      transition-timing-function: ease-in-out;
      transition-duration: 0.2s;
    }
  }
  .green {
    background: #6fb7a0;
  }
  .red {
    background: #f96464;
  }

  .mask {
    width: 100vw;
    max-width: 660px;
    height: 100vw;
    position: fixed;
    top: 0;
    background: #10142480;
    .popout {
      @include flex-set();
      width: 100vw;
      max-width: 660px;
      // height: 500px;
      background: #101424;
      // opacity: 0.8;
      position: fixed;
      bottom: 0;
      z-index: 110;
      padding: 10px;
      .label {
        @include flex-set();
        justify-content: space-between;
        width: 100%;
        margin: 5px 10px;
        padding: 5px;
        color: #ccc;
        // .left {
        // }
        // .right {
        // }
        .amount {
          width: calc(100% - 90px);
          border: none;
          background: none;
          color: #ffffff;
          text-align: right;
          font-weight: bold;
          font-size: 16px;
        }
        input {
          outline: none;
        }
        /* Chrome, Safari, Edge, Opera */
        input[type="number"]::-webkit-outer-spin-button,
        input[type="number"]::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Firefox */
        input[type="number"] {
          -moz-appearance: textfield;
        }
        .bold {
          font-weight: bold;
          color: #ffffff;
        }
      }
      .msg {
        font-size: 12px;
        color: #ff0000;
        text-align: center;
        font-weight: bold;
        text-align: right;
        width: 100%;
        height: 15px;
        margin: 0 10px;
      }
      .buy_type {
        @include flex-set();
        border: 1px solid #888888;
        border-radius: 8px;
        width: 100%;
        margin: 10px;
        overflow: hidden;
        .btn {
          width: 50%;
          padding: 10px;
          cursor: pointer;
        }
        .left {
          border-right: 1px solid #888888;
        }
      }
      .amount_box {
        @include flex-set();
        justify-content: space-between;
        width: 100%;
        margin: 10px;
        margin-bottom: 50px;
        .amount_btn {
          border: 1px solid #ffffff;
          border-radius: 2px;
          width: 13%;
          padding: 10px 0;
          cursor: pointer;
          &:active {
            background: #0382c9;
            border: 1px solid #0382c9;
          }
        }
        .clicked {
          background: #0382c9;
          border: 1px solid #0382c9;
        }
      }
      .border_bottom {
        border-bottom: 1px #0382c9 solid;
      }
      .red_border_bottom {
        border-bottom: 1px #ff0000 solid;
      }
      .button_box {
        width: 50%;
        padding: 5px;
        margin-bottom: 10px;
        .button {
          height: 38px;
          border-radius: 4px;
          line-height: 38px;
          text-align: center;
          &:hover {
            cursor: pointer;
            transform: scale(1.05);
            transition-timing-function: ease-in-out;
            transition-duration: 0.2s;
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
// @import "@/assets/style/globalstyle.scss";
// body {
//   ::v-deep .el-message-box__wrapper {
//     @include flex-set();
//   }
// }
.alert {
  margin: 40vh 0;
  max-width: 90vw;
  .status {
    font-weight: bold;
    font-size: 16px;
  }
  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-bottom: 1px #eeeeee solid;
  }
}
</style>
