export default {
  usercenter: "会员中心",
  assetManagement: "资产管理",
  orderCenter: "订单中心",
  newsCenter: "新闻中心",
  login: "登入",
  register: "注册",
  loginOut: "登出",
  balance: "余额",
  charge: "充值",
  withDraw: "提币",
  customerServiceEmail: "客服信箱",
  zht: "繁体中文",
  zhs: "简体中文",
  en: "英文",
  logoutSuccess: "登出成功",
};
