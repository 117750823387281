<template>
  <div
    class="marquee_box"
    @mouseover="paused == true"
    @mouseleave="paused == false"
  >
    <font-awesome-icon class="btn" icon="fa-solid fa-bullhorn" />
    <marquee-text class="marquee" :duration="20" :repeat="5" :paused="paused">
      <div class="test">{{ $t("home.marqueeText1") }}</div>
    </marquee-text>
  </div>
</template>

<script>
export default {
  name: "NewsCrawlComponent",
  props: {},
  components: {},
  data() {
    return {
      paused: false,
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.marquee_box {
  background: #22222d;
  border-radius: 100px;
  margin: 0 1rem 1rem;
  padding: 10px;
  display: flex;
  .marquee {
    margin-left: 5px;
    .test {
      margin-right: 200px;
    }
  }
}
</style>
