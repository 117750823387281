import Vue from "vue";
import VueI18n from "vue-i18n";

import eng from "./lang/eng";
import zhs from "./lang/zhs";
import zht from "./lang/zht";

Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: eng,
  fallbackLocale: "eng",
  messages: { eng, zht, zhs },
  // messages: { eng },
  silentTranslationWarn: true,
});

export default i18n;
