<template>
  <div class="MarketKlineView">
    <CoinInfoComponent :coinList="coinList" :marketIndex="marketIndex" />
    <KlineComponent />
    <BuyAndSellComponent
      v-if="marketId"
      :marketId="marketId"
      :coinData="coinList[marketIndex]"
    />
  </div>
</template>

<script>
import CoinInfoComponent from "@/components/MarketKline/CoinInfoComponent.vue";
import KlineComponent from "@/components/MarketKline/KlineComponent.vue";
import BuyAndSellComponent from "@/components/MarketKline/BuyAndSellComponent.vue";

import { getMarkets } from "@/services/Api.js";

export default {
  name: "MarketKlineView",
  components: {
    CoinInfoComponent,
    KlineComponent,
    BuyAndSellComponent,
  },
  data() {
    return {
      marketName: "",
      marketIndex: null,
      marketId: null,
      coinList: [],
      coinListNew: [],
      mtf: null,
      getDataInterval: null,
    };
  },
  mounted() {
    console.log(this.$route.params.marketName);
    this.marketName = this.$route.params.marketName;

    this.getData();
    this.getDataInterval = setInterval(this.getData, 30000);
  },
  beforeDestroy() {
    clearInterval(this.getDataInterval);
  },
  methods: {
    getData() {
      this.coinListNew = [];
      getMarkets()
        .then((res) => {
          console.log(res);
          const tempCoinList = res.data;

          tempCoinList.map((item, index) =>
            item.name === "MTF/USDT"
              ? (this.mtf = tempCoinList[index])
              : this.coinListNew.push(tempCoinList[index])
          );

          this.coinListNew.unshift(this.mtf);
          this.coinList = this.coinListNew;

          this.coinList.map((item, index) =>
            item.name === this.marketName ? (this.marketIndex = index) : null
          );

          this.coinList.map((item) =>
            item.name === this.marketName ? (this.marketId = item.id) : null
          );
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.MarketKlineView {
  padding-bottom: 80px;
}
</style>
