<template>
  <div class="withdrawView">
    <h1 class="title"> {{ $t("withdraw.title") }}</h1>

    <p>
      {{ $t("withdraw.enterWalletAddressAndPrice") }}
      <br />
      {{ $t("withdraw.contactForServiceAfterSend") }}
    </p>

    <div class="input_box">
      <div>{{ $t("withdraw.coinWalletAddress") }}</div>
      <input
        class="input"
        type="text"
        :placeholder="$t('withdraw.enterWalletAddress')"
        v-model="address"
      />
    </div>

    <div class="input_box">
      <div>{{ $t("withdraw.withdrawPrice") }}</div>
      <input
        class="input"
        type="text"
        :placeholder="$t('withdraw.enterWithdrawPrice')"
        v-model="amount"
      />
    </div>

    <button class="btn" @click="handleClick">{{ $t("withdraw.send") }}</button>
  </div>
</template>

<script>
import { postTradeList } from "@/services/Api.js";

export default {
  name: "WithdrawView",

  data() {
    return {
      address: "",
      amount: "",
    };
  },

  methods: {
    handleClick() {
      if (this.address == "" || this.amount == "") {
        this.$message({
          message: this.$t("withdraw.inputIncorrect"),
          type: "error",
          offset: 70,
        });
        return;
      }
      var postdata = {
        address: this.address,
        amount: this.amount,
      };
      postTradeList(postdata)
        .then((res) => {
          console.log(res);
          this.$alert(this.$t("withdraw.contactForService"), {
            confirmButtonText: this.$t("withdraw.confirm"),
            type: "alert",
            offset: 70,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.withdrawView {
  height: 100%;

  .title {
    font-size: 21px;
    font-weight: bold;
    margin-top: 1rem;
  }

  p {
    padding: 1rem;
    line-height: 1.5;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 1rem;
  }

  .input_box {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 75%;

    &:first-of-type {
      margin-top: 1rem;
    }

    div {
      width: 100%;
      padding: 12px;
      text-align: left;
    }

    .input {
      width: 100%;
      border: 1px solid #eeefee;
      padding: 12px;
      outline: none;
      border-radius: 0;
      border-radius: 4px;
      font-size: 16px;
      margin-bottom: 24px;
    }
  }

  .btn {
    width: 75%;
    background: #2f83e1;
    padding: 12px;
    color: #fff;
    border: none;
    border-radius: 6px;
    font-size: 16px;
    margin-top: 24px;
  }
}
</style>
