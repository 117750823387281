export default {
  time: "時間",
  price: "價格",
  volume: "數量",
  tradeRecord: "市場交易紀錄",
  pleaseSelect: "請選擇",
  upsAndDowns: "漲跌",
  highPrice: "最高價",
  lowPrice: "最低價",
  volume2: "成交量",
  buyUp: "買漲",
  buyDown: "買跌",
  sendOrder: "下單",
  accountBalance: "帳戶餘額：",
  orderTime: "下單時間",
  openTime: "預測開獎時間",
  type: "倉位",
  price2: "金額",
  pleaseInputPrice: "請輸入金額",
  insufficientBalance: "餘額不足",
  reset: "重置",
  confirmOrder: "確認下單",
  cancel: "取消",
  loginBeforeOrder: "下單前需先登入",
  orderPriceMustGreaterThan0: "下單金額必須大於0",
  temporaryCanNotOrder: "暫時無法下單 請稍後再試",
  tradingPair: "交易對",
  predictDirection: "預測方向",
  predictPrice: "預測金額",
  orderSuccess: "下單成功",
  confirm: "確認",
  orderID: "訂單號",
};
