<template>
  <div class="FourBtnComponent" v-if="coinList">
    <router-link
      class="btn"
      :to="{
        name: 'marketkline',
        params: {
          marketName: item.name,
        },
      }"
      v-for="(item, index) in coinList"
      :key="index"
      v-show="index < 4"
    >
      <div class="row">
        <span class="value">{{ item.name }}</span>
        <span class="value green" :class="[{ red: gainFun(item) < 0 }]">
          <font-awesome-icon
            icon="fa-solid fa-arrow-down"
            v-if="gainFun(item) < 0"
          />
          <font-awesome-icon icon="fa-solid fa-arrow-up" v-else />
          {{ gainFun(item) }}
        </span>
      </div>
      <div class="row">
        <span class="value green" :class="[{ red: gainFun(item) < 0 }]">
          {{ item.ticker.last }}
        </span>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "FourBtnComponent",
  props: { coinList: { Object } },
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    gainFun(item) {
      return item.ticker.open == 0
        ? 0
        : parseFloat(
            ((item.ticker.last - item.ticker.open) / item.ticker.open).toFixed(
              4
            )
          );
    },
  },
};
</script>

<style lang="scss" scoped>
.FourBtnComponent {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 1rem 1rem 0;

  .btn {
    display: flex;
    border: 1px solid hsla(0, 0%, 100%, 0.2);
    border-radius: 3px;
    margin-bottom: 1rem;
    flex-direction: column;
    justify-content: space-between;
    padding: 12px 8px;
    width: calc(50% - 0.5rem);
    color: #ffffff;

    .row {
      display: flex;
      align-items: center;

      &:last-of-type {
        margin-top: 5px;
        justify-content: flex-end;
        span {
          font-size: 16px;
        }
      }

      span {
        font-size: 14px;
        &:first-of-type {
          margin-right: 4px;
        }
      }
    }

    .green {
      color: #29a59d;
    }

    .red {
      color: #f96464;
    }
  }
}
</style>
