export default {
  orderCenter: "Order Center",
  orderno: "Order number",
  orderTime: "Order time",
  predictOpenLotteryTime: "Predict the lottery opening time",
  tradingPair: "Trading Pair",
  type: "Position",
  predictDirection: "Prediction Direction",
  predictPrice: "Predicted Amount",
};
