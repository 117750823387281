<template>
  <div class="charge">
    <h1 class="title">{{ $t("charge.title") }}</h1>

    <el-tabs v-model="activeName" @tab-click="handleClick" stretch>
      <el-tab-pane label="ERC" name="ERC">
        <p>
          {{ $t("charge.chargeUSDTIntoThisWallet") }}
          <br />
          {{ $t("charge.contactUsToHandle") }}
        </p>
        <div class="wallet">
          <div>{{ $t("charge.coinWalletAddress") }}:</div>
          <div class="coinAddress">{{ coinWallet1 }}</div>
          <div>
            <span class="copy" @click="copy(coinWallet1)">{{
              $t("charge.copyCoinWalletAddress")
            }}</span>
          </div>
          <img src="../assets/images/ERC.png" alt="qrcode" />
        </div>
      </el-tab-pane>
      <el-tab-pane label="TRC" name="TRC">
        <p>
          {{ $t("charge.chargeUSDTIntoThisWallet") }}
          <br />
          {{ $t("charge.contactUsToHandle") }}
        </p>
        <div class="wallet">
          <div>{{ $t("charge.coinWalletAddress") }}:</div>
          <div class="coinAddress">{{ coinWallet2 }}</div>
          <div>
            <span class="copy" @click="copy(coinWallet2)">{{
              $t("charge.copyCoinWalletAddress")
            }}</span>
          </div>
          <img src="../assets/images/TRC.png" alt="qrcode" />
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  name: "ChargeView",

  data() {
    return {
      coinWallet1: "0xdc4D94f4077ad31b18098d1333DC83C16fDD2b96",
      coinWallet2: "THrv5mztx1eVxZ1tBfbTeGoX6NCVeur4fy",
      activeName: "ERC",
    };
  },
  methods: {
    copy(val) {
      this.$copyText(val)
        .then(() => {
          this.$message({
            message: this.$t("charge.copySuccess"),
            type: "success",
            offset: 70,
          });
        })
        .catch((err) => {
          console.log(err);
          this.$message({
            message: this.$t("charge.copyFail"),
            type: "error",
            offset: 70,
          });
        });
    },

    handleClick(tab, event) {
      console.log(tab, event);
    },
  },
};
</script>

<style lang="scss" scoped>
.charge {
  height: 100%;

  .title {
    font-size: 21px;
    font-weight: bold;
    margin-top: 1rem;
  }

  p {
    padding: 1rem;
    line-height: 1.5;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 1rem;
  }

  .wallet {
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem;

    .coinAddress {
      word-break: break-all;
      padding: 0 1rem;
    }

    div {
      margin-bottom: 0.5rem;

      &:last-of-type {
        margin-bottom: 4rem;
      }

      span {
        &.copy {
          border: 1px solid #2f83e1;
          display: inline-flex;
          padding: 0.5rem;
          border-radius: 5px;
          cursor: pointer;
          margin-top: 1rem;
          color: #2f83e1;
          &:hover {
            transform: scale(1.1);
            transition-timing-function: ease-in-out;
            transition-duration: 0.2s;
          }
        }
      }
    }

    img {
      width: 300px;
      margin: 0 auto;
    }
  }
}
</style>

<style lang="scss">
.el-tabs__item {
  color: #fff !important;
}
.is-active {
  color: #2f83e1 !important;
}
</style>
