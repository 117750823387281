<template>
  <div class="forgotPassword">
    <form class="from" @submit="(e) => e.preventDefault()">
      <button class="title" data-type="email">
        {{ $t("forgotpassword.useEmailToReset") }}
      </button>

      <input
        class="input"
        type="email"
        :laceholder="$t('forgotpassword.emailAddress')"
        v-model="email"
        required
      />

      <div class="row">
        <input
          class="input"
          type="text"
          :placeholder="$t('forgotpassword.enterCaptcha')"
          v-model="verify"
          required
        />
        <div class="verify" @click="refreshCode">
          <SIdentify :identifyCode="identifyCode"></SIdentify>
        </div>
      </div>

      <button class="btn" @click="submit">
        {{ $t("forgotpassword.sendResetMail") }}
      </button>
    </form>
  </div>
</template>

<script>
import SIdentify from "@/components/Sldentify/Sldentify.vue";
import { postResetEmail } from "@/services/Api.js";

export default {
  name: "ForgotPassword",
  components: { SIdentify },
  data() {
    return {
      email: "",
      verify: "",
      identifyCode: "1234",
      identifyCodes: "0123456789abcdwerwshdjeJKDHRJHKOOPLMKQ",
    };
  },
  mounted() {
    this.refreshCode();
  },
  methods: {
    refreshCode() {
      this.identifyCode = "";
      this.makeCode(this.identifyCodes, 4);
    },

    randomNum(min, max) {
      max = max + 1;
      return Math.floor(Math.random() * (max - min) + min);
    },

    // 隨機生成驗證碼字符串
    makeCode(data, len) {
      for (let i = 0; i < len; i++) {
        this.identifyCode += data[this.randomNum(0, data.length - 1)];
      }
    },

    submit() {
      if (this.email !== "") {
        if (this.verify !== this.identifyCode) {
          this.$message({
            message: this.$t("forgotpassword.captchaDoesNotMatch"),
            type: "error",
            offset: 70,
          });
          return;
        }

        postResetEmail({ email: this.email })
          .then((res) => {
            console.log(res);
            this.$message({
              message: this.$t("forgotpassword.mailHasBeenSent"),
              type: "success",
              offset: 70,
            });
          })
          .catch((err) => {
            console.log(err);
            this.$message({
              message: this.$t("forgotpassword.errorOccursWhenSendingMail"),
              type: "error",
              offset: 70,
            });
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.forgotPassword {
  width: 100%;
  min-height: 100vh;
  background-color: #292d39;
  padding-bottom: 2rem;

  .from {
    background-color: #fff;
    padding: 44px 16px;
    border-radius: 3px;
    margin-top: 2rem;
    width: calc(100% - 2rem);
    margin: 0 auto;
    margin-top: 2rem;

    .row {
      display: flex;
      margin-bottom: 24px;

      input {
        width: 60%;
        margin-bottom: 0;
        margin-right: 8px;
      }

      .verify {
        width: 40%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .title {
      border: none;
      font-size: 20px;
      margin: 0 10px;
      cursor: pointer;
      border-bottom: 2px solid transparent;
      padding: 6px;
      background: transparent;
      margin-bottom: 24px;
      border-color: #0382c9;
      color: #000;
    }

    .input {
      width: 100%;
      border: 1px solid #eeefee;
      padding: 12px;
      outline: none;
      border-radius: 0;
      border-radius: 4px;
      font-size: 16px;
      margin-bottom: 24px;
    }

    label {
      color: #000;
      display: flex;
      font-size: 16px;
      text-align: left;
    }

    .btn {
      width: 100%;
      background: #2f83e1;
      padding: 12px;
      color: #fff;
      border: none;
      border-radius: 6px;
      font-size: 16px;
      &:hover {
        cursor: pointer;
      }
    }

    .footer {
      display: flex;
      justify-content: space-between;
      color: #000;
      margin-top: 2rem;

      a {
        color: #2f83e1;
      }
      &:last-of-type {
        color: #757575;
      }
    }
  }
}
</style>
