export default {
  homeInfoTitle1: "High-quality Project Assets",
  homeInfoText1:
    "A detailed and rigorous project online review process with physical industry behind each project Ensure projects recommended to users are feasible and potential in value growth",
  homeInfoTitle2: "Regulated Operation Management",
  homeInfoText2:
    "A Regulated digital equity trading platform with an AUSTRAC license Provide full-processed coaching and supervision with the third-party agencies",
  homeInfoTitle3: "Strong Capital Strength",
  homeInfoText3:
    "Refuse the entry of investment institutions Support project development and marketing with self-own capital in the early stage",
  mtfTokenWhitePaper: "MTF Token White Paper",
  latestPrice: "Latest Price",
  upsAndDowns: "Ups and Downs",
  lowPrice: "Lowest Price",
  volume2: "Volume",
  orderPrice: "Transaction Amount",
  charge: "Recharge",
  withdraw: "Withdraw",
  orderlist: "Order",
  contactService: "Customer Service",
  loginFirst: "Please login first",
  marqueeText1:
    "Invitation activities are popular, and the surprise benefits are endless",
};
