import navbar from "./zht/navbar";
import home from "./zht/home";
import footer from "./zht/footer";
import kline from "./zht/kline";
import charge from "./zht/charge";
import withdraw from "./zht/withdraw";
import order from "./zht/order";
import forgotpassword from "./zht/forgotpassword";

export default {
  navbar: {
    ...navbar,
  },
  home: {
    ...home,
  },
  footer: {
    ...footer,
  },
  kline: {
    ...kline,
  },
  charge: {
    ...charge,
  },
  withdraw: {
    ...withdraw,
  },
  order: {
    ...order,
  },
  forgotpassword: {
    ...forgotpassword,
  },
};
