export default {
  usercenter: "會員中心",
  assetManagement: "資產管理",
  orderCenter: "訂單中心",
  newsCenter: "新聞中心",
  login: "登入",
  register: "註冊",
  loginOut: "登出",
  balance: "餘額",
  charge: "充值",
  withDraw: "提幣",
  customerServiceEmail: "客服信箱",
  zht: "繁體中文",
  zhs: "简体中文",
  en: "英文",
  logoutSuccess: "登出成功",
};
