<template>
  <el-carousel
    class="secondSwiperComponent"
    height="42vw"
    indicator-position="none"
  >
    <el-carousel-item v-for="item in banner_data" :key="item">
      <img class="img" :src="item" />
    </el-carousel-item>
  </el-carousel>
</template>
<script>
// import axios from "axios";

export default {
  name: "secondSwiperComponent",
  props: {},
  components: {},
  data() {
    return {
      banner_data: [
        "https://assets.bitex.cat/bitex-data/images/1.jpg",
        "https://assets.bitex.cat/bitex-data/images/2.jpg",
        "https://assets.bitex.cat/bitex-data/images/3.jpg",
        "https://assets.bitex.cat/bitex-data/images/4.jpg",
      ],
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
// @import "@/assets/style/globalstyle.scss";
.secondSwiperComponent {
  margin: 1rem;
  border-radius: 5px;
  max-height: 300px;
  ::v-deep .el-carousel__container {
    max-height: 300px;
    overflow: hidden;
  }
  .el-carousel__item h3 {
    color: #000000;
    font-size: 14px;
    opacity: 0.75;
    line-height: 150px;
    margin: 0;
  }

  .el-carousel__item {
    background-color: #000000;
  }

  .img {
    // height: 100%;
    width: 100%;
  }
}
</style>
