<template>
  <div class="KlineComponent">
    <!-- <el-menu
      :default-active="activeIndex"
      class="el-menu-demo"
      mode="horizontal"
      @select="handleSelect"
    >
      <el-menu-item index="1">圖表</el-menu-item>
      <el-menu-item index="2">掛單簿</el-menu-item>
      <el-menu-item index="3">市場交易紀錄</el-menu-item>
    </el-menu> -->
    <KLine />
    <el-divider></el-divider>
    <!-- <HandiCap />
    <el-divider></el-divider> -->
    <div class="title">{{ $t("kline.tradeRecord") }}</div>
    <TradeRecord />
  </div>
</template>

<script>
import KLine from "@/components/MarketKline/kline/KLine.vue";
import TradeRecord from "@/components/MarketKline/kline/TradeRecord.vue";
// import HandiCap from "@/components/MarketKline/kline/HandiCap.vue";

export default {
  name: "KlineComponent",
  props: {
    // coinList: { Object }
  },
  components: {
    KLine,
    TradeRecord,
    // HandiCap,
  },
  data() {
    return {
      activeIndex: "1",
      currentTab: 1,
    };
  },
  methods: {
    handleSelect(key) {
      console.log(key);
      this.currentTab = key;
    },
  },
};
</script>

<style lang="scss" scoped>
.KlineComponent {
  background: #2a2e39;
  padding: 10px;
  margin-bottom: 80px;
}
</style>

<style lang="scss">
.el-menu.el-menu--horizontal {
  border-bottom: unset !important;
}
.el-menu {
  background-color: unset !important;
  .is-active {
    color: #409eff !important;
  }
  .el-menu-item {
    height: 30px !important;
    line-height: 30px !important;
    &:hover {
      background-color: unset !important;
      color: #409eff !important;
    }
    &:focus {
      background-color: unset !important;
      color: #409eff !important;
    }
  }
}
</style>
