export default {
  usercenter: "Member Center",
  assetManagement: "Asset Management",
  orderCenter: "Order Center",
  newsCenter: "News Center",
  login: "Login",
  register: "Register",
  loginOut: "Logout",
  balance: "Balance",
  charge: "Recharge",
  withDraw: "Withdraw",
  customerServiceEmail: "Customer Email",
  zht: "Traditional Chinese",
  zhs: "Simplified Chinese",
  en: "English",
  logoutSuccess: "Logout successful",
};
