// import Cookie from 'js-cookie'
import { Api } from "./Base";

// 非權限API
export const getMarkets = () => Api.get("/api/v2/markets.json"); //交易对
export const postLogin = (data) => Api.post("/web/session.json", data); //登入
export const postIdentities = (data) => Api.post("/api/identities.json", data); //註冊
export const getLogout = () => Api.delete("/web/session.json"); //登出
export const postMembers = (data) => Api.post("/web/members.json", data); //郵箱註冊
export const getKline = (data) => Api.get(`/api/v2/k.json?${data}`); //K線
export const postResetEmail = (data) =>
  Api.post("/web/reset_passwords.json", data); //發送信箱重製密碼
export const getPredictions = (data) =>
  Api.get(`/web/predictions.json?${data}`); //取得訂單紀錄
export const getdepthMarket = (name) => Api.get(`api/v2/depth?market=${name}`); //市場深度
export const getTradeList = (data) => Api.get(`/api/v2/trades?${data}`); //歷史成交列表
export const postTradeList = (data) =>
  Api.post("/web/accounts/usdt/withdraws", data); //提款

//權限API
export const getAccounts = () => Api.get("/web/accounts.json"); //獲取用戶訊息
export const getUser = () => Api.get("/web/settings.json"); //獲取用戶訊息
export const postPredictions = (data) =>
  Api.post("/web/predictions.json", data); //下注買漲買跌
