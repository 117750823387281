export default {
  time: "时间",
  price: "价格",
  volume: "数量",
  tradeRecord: "市场交易纪录",
  pleaseSelect: "请选择",
  upsAndDowns: "涨跌",
  highPrice: "最高价",
  lowPrice: "最低价",
  volume2: "成交量",
  buyUp: "买涨",
  buyDown: "买跌",
  sendOrder: "下单",
  accountBalance: "帐户余额：",
  orderTime: "下单时间",
  openTime: "预测开奖时间",
  type: "仓位",
  price2: "金额",
  pleaseInputPrice: "请输入金额",
  insufficientBalance: "余额不足",
  reset: "重置",
  confirmOrder: "确认下单",
  cancel: "取消",
  loginBeforeOrder: "下单前需先登入",
  orderPriceMustGreaterThan0: "下单金额必须大于0",
  temporaryCanNotOrder: "暂时无法下单 请稍后再试",
  tradingPair: "交易对",
  predictDirection: "预测方向",
  predictPrice: "预测金额",
  orderSuccess: "下单成功",
  confirm: "确认",
  orderID: "订单号",
};
