<template>
  <div class="klinebox">
    <div class="chart-top-bar">
      <span
        class="period"
        :class="[{ select: select == 1 }]"
        @click="changeKlineType(1)"
      >
        1m
      </span>
      <span
        class="period"
        :class="[{ select: select == 2 }]"
        @click="changeKlineType(2)"
      >
        5m
      </span>
      <span
        class="period"
        :class="[{ select: select == 3 }]"
        @click="changeKlineType(3)"
      >
        15m
      </span>
      <span
        class="period"
        :class="[{ select: select == 4 }]"
        @click="changeKlineType(4)"
      >
        30m
      </span>
      <span
        class="period"
        :class="[{ select: select == 5 }]"
        @click="changeKlineType(5)"
      >
        1H
      </span>
      <span
        class="period"
        :class="[{ select: select == 6 }]"
        @click="changeKlineType(6)"
      >
        2H
      </span>
      <span
        class="period"
        :class="[{ select: select == 7 }]"
        @click="changeKlineType(7)"
      >
        4H
      </span>
      <span
        class="period"
        :class="[{ select: select == 8 }]"
        @click="changeKlineType(8)"
      >
        6H
      </span>
      <span
        class="period"
        :class="[{ select: select == 9 }]"
        @click="changeKlineType(9)"
      >
        12H
      </span>
      <span
        class="period"
        :class="[{ select: select == 10 }]"
        @click="changeKlineType(10)"
      >
        1D
      </span>
      <span
        class="period"
        :class="[{ select: select == 11 }]"
        @click="changeKlineType(11)"
      >
        1W
      </span>
    </div>
    <div
      id="kline"
      ref="kline"
      v-loading="loading"
      element-loading-background="#00000080"
    ></div>
  </div>
</template>

<script>
import { init } from "klinecharts";
import moment from "moment";
import { getKline } from "@/services/Api.js";

export default {
  name: "KLine",
  data() {
    return {
      chart: undefined,
      loading: true,
      latestRecord: null,
      timer: null,
      currentKlineType: 5,
      select: 2,
      klineSettings: {
        // 网格线
        grid: {
          show: false,
          // 网格水平线
          horizontal: {
            show: false,
          },
        },
        candle: {
          priceMark: {
            last: {
              text: {
                size: 10,
              },
            },
          },
          tooltip: {
            // showRule: "none",
            labels: ["T:", "O:", "C:", "H:", "L:"],
            text: {
              size: 10,
              marginLeft: 0,
              marginTop: 6,
              marginRight: 5,
              marginBottom: 0,
            },
          },
        },
      },
    };
  },
  watch: {
    latestRecord(newValue) {
      this.chart.updateData(newValue);
    },
  },
  mounted() {
    this.chart = init(this.$refs.kline, this.klineSettings);
    this.chart.setPriceVolumePrecision(4, 2);
    this.getKlineData(this.currentKlineType, true);
  },
  methods: {
    changeKlineType(type) {
      this.select = type;
      switch (type) {
        case 1:
          this.currentKlineType = 1;
          break;

        case 2:
          this.currentKlineType = 5;
          break;

        case 3:
          this.currentKlineType = 15;
          break;

        case 4:
          this.currentKlineType = 30;
          break;

        case 5:
          this.currentKlineType = 60;
          break;

        case 6:
          this.currentKlineType = 60 * 2;
          break;

        case 7:
          this.currentKlineType = 60 * 4;
          break;

        case 8:
          this.currentKlineType = 60 * 6;
          break;

        case 9:
          this.currentKlineType = 60 * 12;
          break;

        case 10:
          this.currentKlineType = 60 * 24;
          break;

        case 11:
          this.currentKlineType = 60 * 24 * 7;
          break;

        default:
          break;
      }
      this.getKlineData(this.currentKlineType, true);
    },
    getKlineData(period, init = false) {
      console.log(init);
      if (init == true) {
        this.loading = true;
      }
      var senddata = {
        market: this.$route.params.marketName,
        locale: "zh-CN",
        period: period,
        timestamp: moment().subtract(15, "d").format("X"),
        end: moment().format("X"),
      };
      const queryString = Object.keys(senddata)
        .map((key) => key + "=" + senddata[key])
        .join("&");

      getKline(queryString)
        .then((res) => {
          var klinedata = [];
          for (let i = 0; i < res.data.length; i++) {
            let temp = {
              open: res.data[i][1],
              high: res.data[i][2],
              low: res.data[i][3],
              close: res.data[i][4],
              timestamp: res.data[i][0] * 1000,
              turnover: res.data[i][5],
            };
            klinedata.push(temp);
          }
          if (init == true) {
            this.chart.applyNewData(klinedata);
          } else {
            this.latestRecord = klinedata[klinedata.length - 1];
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    startKlineTimer() {
      this.timer = setInterval(function () {
        this.getKlineData(this.currentKlineType);
      }, 10000);
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/globalstyle.scss";

.chart-top-bar {
  margin-top: 10px;
  overflow: scroll;
  @include flex-set(unset, start);
  .period {
    padding: 2px;
    transition: all 0.2s;
    border-radius: 2px;
    margin-right: 6px;
    &:hover {
      cursor: pointer;
    }
  }
  .select {
    background-color: rgba(54, 98, 236, 0.1);
    color: #3662ec;
  }
}

#kline {
  height: 350px;
  max-width: 660px;
  width: 100%;
  @include flex-set();
  padding: 0 10px;
  margin-top: 5px;
}
</style>
