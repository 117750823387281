<template>
  <div class="navbar">
    <div class="space">
      <div class="logo">
        <img
          src="@/assets/images/Logo.png"
          class="logoimg"
          @click="jump('home')"
        />
      </div>
      <!-- <router-link class="logo" to="/" @click="handleClose">
        
      </router-link> -->
    </div>
    <div class="space">
      <div class="btngroup">
        <font-awesome-icon
          class="btn"
          :icon="!this.drawerOpen ? 'fa-solid fa-bars' : 'fa-solid fa-close'"
          @click="openDrawerFun"
        />
      </div>
    </div>
    <!-- size:134px -->
    <el-drawer
      custom-class="drawer"
      :visible.sync="drawerOpen"
      :direction="direction"
      :before-close="handleClose"
      :show-close="false"
      :withHeader="false"
    >
      <DrawerComponent @handleClose="handleClose" :uadtBalance="uadtBalance" />
    </el-drawer>
  </div>
</template>

<script>
import DrawerComponent from "@/components/Navbar/DrawerComponent.vue";
import { getAccounts } from "@/services/Api.js";

export default {
  name: "NavbarComponent",
  components: {
    DrawerComponent,
  },
  data() {
    return {
      drawerOpen: false,
      direction: "rtl",
      uadtBalance: 0,
    };
  },
  mounted() {},
  methods: {
    handleClose() {
      this.drawerOpen = false;
    },
    openDrawerFun() {
      this.drawerOpen = !this.drawerOpen;
      this.getAccountsFun();
    },
    jump(type) {
      this.handleClose();
      this.$router.push({ name: type }).catch(() => {});
    },

    getAccountsFun() {
      getAccounts()
        .then((res) => {
          console.log(res);
          if (res.data.success) {
            res.data.data.map((item) =>
              item.code === "USDT"
                ? (this.uadtBalance = parseInt(item.balance))
                : null
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/globalstyle.scss";

.navbar {
  height: 64px;
  background: #22222d;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  // max-width: 600px;
  width: 100%;
  z-index: 30000;
  .space {
    width: 33.3%;
    .returnimg {
      @include flex-set(wrap, unset, center);
      .returnImgBtn {
        width: 25px;
        height: 25px;
        margin-left: 11px;
        &:hover {
          cursor: pointer;
        }
      }
    }
    .logo {
      display: flex;
      .logoimg {
        width: 148px;
        margin-left: 17px;
        &:hover {
          cursor: pointer;
        }
      }
      .logotext {
        @include font-set($white, 18px, bold, sans-serif);
        letter-spacing: -0.45px;
        line-height: 22px;
        margin-bottom: 9px;
      }
    }
    .btngroup {
      margin-right: 20px;
      display: flex;
      justify-content: flex-end;
      .btn {
        @include font-set($white, 20px);
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.el-drawer__wrapper {
  height: calc(100% - 64px);
  margin-bottom: 0;
  top: 64px !important;
}
.el-drawer__container {
  overflow: hidden;
}
.el-drawer {
  background: #292d39 !important;
  width: 220px !important;
}
</style>
