export default {
  orderCenter: "訂單中心",
  orderno: "訂單號",
  orderTime: "訂單時間",
  predictOpenLotteryTime: "預測開獎時間",
  tradingPair: "交易對",
  type: "倉位",
  predictDirection: "預測方向",
  predictPrice: "預測金額",
};
