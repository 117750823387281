import Axios from "axios";

export const Api = Axios.create({
  // baseURL: process.env.REACT_APP_BASE_API,
  baseURL: "/",

  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    // "Access-Control-Allow-Origin": "*",
  },
  timeout: 60000,
});

Api.interceptors.request.use(
  (config) => config,
  (error) => Promise.reject(error)
);

Api.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

export const loadingApi = Axios.create({
  baseURL: process.env.REACT_APP_BASE_API,
  withCredentials: false,
  timeout: 60000,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

loadingApi.interceptors.request.use(
  (config) => {
    window.loading(true);
    return config;
  },
  (error) => Promise.reject(error)
);

loadingApi.interceptors.response.use(
  (response) => {
    window.loading(false);
    return response;
  },
  (error) => {
    window.loading(false);
    return Promise.reject(error);
  }
);
