export default {
  title: "Recharge",
  copySuccess: "Copy succeeded",
  copyFail: "Copy failed",
  chargeUSDTIntoThisWallet:
    "Please send the USDT you want to deposit to the following address,",
  contactUsToHandle:
    "Please contact customer service and we will handle it as soon as possible.",
  coinWalletAddress: "Coin Wallet Address",
  copyCoinWalletAddress: "Copy Coin Wallet Address",
};
