export default {
  title: "提币",
  enterWalletAddressAndPrice: "请输入您的币包地址与提款金额,",
  contactForServiceAfterSend: "确认送出后请与客服联系",
  coinWalletAddress: "币包地址",
  enterWalletAddress: "请输入币包地址",
  withdrawPrice: "提款金额",
  enterWithdrawPrice: "请输入提款金额",
  send: "送出",
  inputIncorrect: "有栏位尚未填写",
  contactForService: "请与客服联系",
  confirm: "确认",
};
