export default {
  time: "time",
  price: "price",
  volume: "Quantity",
  tradeRecord: "Market Transaction Record",
  pleaseSelect: "Please select",
  upsAndDowns: "Ups and Downs",
  highPrice: "Highest Price",
  lowPrice: "Lowest Price",
  volume2: "Volume",
  buyUp: "Buy Up",
  buyDown: "Buy Down",
  sendOrder: "Order",
  accountBalance: "Account Balance:",
  orderTime: "Order time",
  openTime: "Predict draw time",
  type: "Position",
  price2: "Amount",
  pleaseInputPrice: "Please enter the amount",
  insufficientBalance: "Insufficient balance",
  reset: "Reset",
  confirmOrder: "Confirm order",
  cancel: "Cancel",
  loginBeforeOrder: "Login is required before placing an order",
  orderPriceMustGreaterThan0: "The order amount must be greater than 0",
  temporaryCanNotOrder:
    "Temporarily unable to place an order, please try again later",
  tradingPair: "Trading Pair",
  predictDirection: "Prediction Direction",
  predictPrice: "Predicted Amount",
  orderSuccess: "Order placed successfully",
  confirm: "Confirm",
  orderID: "Order ID",
};
